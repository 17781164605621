<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2019-12-11 16:07:31
 * @LastEditors  : 王鹏
 * @LastEditTime : 2020-02-11 14:57:39
 -->

<template>
  <div class="mainbody">
    <div class="messagelist">
      <div class="message">
        <div class="message-top">
          <img src="@/assets/jhs/img/tou.png" alt />
          <p>投保人信息</p>
        </div>
        <div class="message-bom">
          <md-field>
            <md-input-item
              title="姓名"
              class="require"
              clearable
              name="apidname"
              v-model="from.customer.apidname"
              placeholder="请填写姓名"
              align="right"
            ></md-input-item>
            <md-field-item title="性别" align="right">
              <md-radio-group v-model="from.customer.apidsex">
                <md-radio-box
                  :style="{ color: theme, 'border-color': theme }"
                  name="男"
                  >男</md-radio-box
                >
                <md-radio-box
                  :style="{ color: theme, 'border-color': theme }"
                  name="女"
                  >女</md-radio-box
                >
              </md-radio-group>
            </md-field-item>
            <md-input-item
              title="年龄"
              class="require"
              clearable
              type="digit"
              name="apidage"
              v-validate="'required'"
              :error="errors.first('apidage')"
              v-model="from.customer.apidage"
              maxlength="2"
              placeholder="请填写真实年龄"
              align="right"
            ></md-input-item>
          </md-field>
        </div>
      </div>
      <div class="message">
        <div class="message-top">
          <img src="@/assets/jhs/img/bei.png" alt />
          <p>被保人信息</p>
          <div
            class="thesame"
            :style="{ color: theme, 'border-color': theme }"
            @click="clonepolicy"
          >
            同投保人
          </div>
        </div>
        <div class="message-bom">
          <md-field>
            <md-input-item
              title="姓名"
              class="require"
              clearable
              name="pidname"
              v-model="from.customer.pidname"
              placeholder="请填写姓名"
              align="right"
            ></md-input-item>
            <md-field-item title="性别" align="right">
              <md-radio-group v-model="from.customer.pidsex">
                <md-radio-box
                  :style="{ color: theme, 'border-color': theme }"
                  name="男"
                  >男</md-radio-box
                >
                <md-radio-box
                  :style="{ color: theme, 'border-color': theme }"
                  name="女"
                  >女</md-radio-box
                >
              </md-radio-group>
            </md-field-item>
            <md-input-item
              title="年龄"
              class="require"
              type="digit"
              maxlength="2"
              clearable
              name="pidage"
              v-validate="'required'"
              :error="errors.first('pidage')"
              v-model="from.customer.pidage"
              placeholder="请填写真实年龄"
              align="right"
            ></md-input-item>
          </md-field>
        </div>
      </div>
      <div v-for="(item, index) of from.prodlist" :key="index">
        <div class="message">
          <div class="message-top">
            <img src="@/assets/jhs/img/zhu.png" alt="" />
            <p>{{ item.prodname }}</p>
            <md-agree
              v-model="item.falg"
              size="lg"
              @change="onChange(item.falg, item.fpordlist, $event)"
            >
            </md-agree>
          </div>
          <div class="message-bom">
            <div class="message-bompd">
              <md-field-item
                :title="
                  item.isreversal != 1
                    ? '年缴保费'
                    : item.isfixed == 0
                    ? '保险金额(固定)'
                    : '保险金额'
                "
                class="rightje"
                v-if="!item.ishasbe"
              >
                <span v-if="item.isfixed == 0">{{ item.initmoney }}</span>
                <input
                  v-model="item.initmoney"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入保险金额"
                  style="text-align: right; font-size: 15px"
                  v-else
                />
              </md-field-item>
              <md-field-item
                title="缴费年限"
                :content="item.endnum"
                @click="showSelector(item, index)"
                arrow="arrow-right"
                align="right"
                :solid="false"
              />
              <md-field-item
                title="保障方案"
                v-if="item.jhname && item.classjh.length > 1"
                :content="item.jhname"
                @click="selectzxjh(item, index)"
                arrow="arrow-right"
                align="right"
                :solid="false"
              />
              <div v-for="(ieffdata, ieff) of item.effinfo" :key="ieff">
                <md-field-item
                  :title="ieffdata.name"
                  :content="ieffdata.value"
                  @click="showieffdata(ieffdata, index, ieff)"
                  arrow="arrow-right"
                  align="right"
                  :solid="false"
                />
              </div>
            </div>
          </div>
          <div class="message" v-for="(ic, i) of item.fpordlist" :key="i">
            <div class="message-top">
              <img src="@/assets/jhs/img/fu.png" alt="" />
              <p>{{ ic.fprodname }}</p>
              <md-agree v-model="ic.falg" size="lg" :disabled="!item.falg">
              </md-agree>
            </div>
            <div class="message-bom">
              <div class="message-bompd">
                <md-field-item
                  :title="
                    ic.isreversal != 1
                      ? '年缴保费'
                      : ic.isfixed == 0
                      ? '保险金额(固定)'
                      : '保险金额'
                  "
                  class="rightje"
                  v-if="!ic.ishasbe"
                >
                  <span v-if="ic.isfixed == 0">{{ ic.initmoney }}</span>
                  <input
                    v-model="ic.initmoney"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入保险金额"
                    v-else
                    style="text-align: right; font-size: 15px"
                  />
                </md-field-item>
                <md-field-item
                  title="缴费年限"
                  :content="ic.endnum"
                  @click="showSelect(ic, index, i)"
                  arrow="arrow-right"
                  align="right"
                  :solid="false"
                />
                <md-field-item
                  title="保障方案"
                  v-if="ic.jhname && ic.classjh.length > 1"
                  :content="ic.jhname"
                  @click="selectjh(ic, index, i)"
                  arrow="arrow-right"
                  align="right"
                  :solid="false"
                />
                <div v-for="(ieffdata, ieff) of ic.effinfo" :key="ieff">
                  <md-field-item
                    :title="ieffdata.name"
                    :content="ieffdata.value"
                    @click="showiedata(ieffdata, index, i, ieff)"
                    arrow="arrow-right"
                    align="right"
                    :solid="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 影响因子 -->
          <md-selector
            v-model="isieffdata"
            default-value="1"
            :data="ieffdata.data"
            max-height="30vh"
            min-height="30vh"
            title="请选择"
            large-radius
            @choose="onSelectieff($event, ieffdataindex, ieffdatai)"
          ></md-selector>
          <!-- 主险缴费年限 -->
          <md-selector
            v-model="isSelectorShow"
            default-value="2"
            :data="list.eyearnum"
            max-height="30vh"
            min-height="30vh"
            title="缴费年限"
            large-radius
            @choose="onSelectorChoose($event, listindex)"
          ></md-selector>
          <!-- 附加险缴费年限 -->
          <md-selector
            v-model="isSelecShow"
            default-value="2"
            :data="listic.feyearnum"
            title="缴费年限"
            max-height="30vh"
            min-height="30vh"
            large-radius
            @choose="onselectValue($event, listicindex, listici)"
          ></md-selector>
          <!-- 主险保障方案 -->
          <md-selector
            v-model="isSeleczxJhShow"
            default-value="2"
            :data="list.classjh"
            title="保障方案"
            max-height="30vh"
            min-height="30vh"
            large-radius
            @choose="onselectzxjh($event, listindex)"
          ></md-selector>
          <!-- 附加险保障方案 -->
          <md-selector
            v-model="isSelecJhShow"
            default-value="2"
            :data="listic.classjh"
            title="保障方案"
            max-height="30vh"
            min-height="30vh"
            large-radius
            @choose="onselectfjh($event, listicindex, listici)"
          ></md-selector>
          <!-- 附加险缴费年限 -->
          <md-selector
            v-model="isieffdatas"
            default-value="2"
            :data="ieffdatas.data"
            title="请选择"
            max-height="30vh"
            min-height="30vh"
            large-radius
            @choose="
              onSelectieffs($event, ieffdataindexs, ieffdatais, ieffisieff)
            "
          ></md-selector>
        </div>
      </div>
    </div>
    <div class="messagelist-bom">
      <div class="messagelist-bomrg">
        <md-button
          type="primary"
          @click="submit"
          :style="{ backgroundColor: theme }"
          :loading="loading"
          :inactive="inactive"
          >生成计划书</md-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  personclassclist,
  infolist,
  generatereport,
} from "@/api/jhs/productlist/index";
import {
  Stepper,
  Field,
  FieldItem,
  Agree,
  Check,
  CheckGroup,
  Toast,
} from "mand-mobile";
import { getStorage, stopScroll, isAndroid } from "@/lib/util";
export default {
  data() {
    return {
      ieffdatatrue: "",
      loading: false,
      inactive: false,
      from: {
        customer: {
          apidname: "",
          apidsex: "男", // 投保人性别
          pidname: "",
          apidage: "30", // 投保人年龄
          pidage: "30", // 被保人年龄
          pidsex: "男", // 被保人性别
        },
        prodlist: [],
      },
      ieffdatais: "",
      ieffdatas: [], // 主险区间
      ieffdataindexs: "", // 主险对应坐标
      ieffdatai: "",
      ieffdata: [], // 主险区间
      ieffdataindex: "", // 主险对应坐标
      list: [], // 主险区间
      listindex: "", // 主险对应坐标
      listic: [], // 附加险区间
      listicindex: "", // 附加险 主险对应坐标
      listici: "", // 附加险对应坐标
      reportList: [],
      prods: [],
      ishas: [], // 验证显示险种
      addform: {
        name: "",
        relation: "00",
        sex: "1",
        birthdate: "",
        cardtype: "",
        cardno: "",
      },
      pageNo: 1,
      pageSize: 6,
      data: [
        [
          {
            value: "1",
            label: "1",
          },
        ],
      ],
      agreeConf: {
        checked: true,
        name: "agree0",
        size: "md",
        disabled: false,
        introduction: "选中状态",
      },
      selectorValue: "",
      selectValue: "",
      isieffdatas: false,
      isieffdata: false,
      isSelectorShow: false,
      isSelecShow: false,
      isPopupShow: false, // 右侧
      value: 30000,
      ipontype: false,
      isSeleczxJhShow: false,
      isSelecJhShow: false,
    };
  },
  components: {
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Stepper.name]: Stepper,
    [Agree.name]: Agree, // 单选
    [Check.name]: Check, // 多选
    [CheckGroup.name]: CheckGroup, // 多选
    [Toast.component.name]: Toast.component,
  },
  created() {
    this.theme = getStorage("theme", "");
    this.user = getStorage("u_s", {});
    this.proddatalist = getStorage("proddatalist", {});
    this.prodcpcodelist = getStorage("prodcpcodelist", {});
    this.cpinfolist();
  },
  watch: {
    "$route.name"(nVal) {
      const judgedr = navigator.userAgent.indexOf("dongrui");
      if (isAndroid() && judgedr != -1) {
        window.JYTitleInterface.openPage();
      }
    },
  },
  methods: {
    //同投保人按钮
    clonepolicy() {
      this.from.customer.pidname = this.from.customer.apidname;
      this.from.customer.pidsex = this.from.customer.apidsex;
      this.from.customer.pidage = this.from.customer.apidage;
    },
    onChange(checked, children) {
      if (!checked) {
        for (let item in children) {
          children[item].falg = false;
        }
      }
    },
    cpinfolist(type) {
      let data = {
        comid: this.$route.query.comid,
        familyno: this.$route.query.familyno,
        memid: this.$route.query.memid,
        list: this.prodcpcodelist,
      };
      this.inactive = true;
      personclassclist(data).then((res) => {
        for (let item in res.data.data) {
          this.ishas.push(res.data.data[item].prodcode);
        }
        this.from.prodlist = res.data.data;
        this.from.prodlist.forEach((v) => {
          for (let item in v.effinfo) {
            v.effinfo[item].value = v.effinfo[item].data[0].value;
          }
          v.endnum = v.eyearnum[0].label;
          for (let item in v.fpordlist) {
            v.fpordlist[item].endnum = v.fpordlist[item].feyearnum[0].label;
            for (let ic in v.fpordlist[item].effinfo) {
              v.fpordlist[item].effinfo[ic].value =
                v.fpordlist[item].effinfo[ic].data[0].value;
            }
          }
        });
        this.inactive = false;
      });
    },
    submit() {
      // 生成计划书
      this.$validator.validate().then((valid) => {
        if (valid) {
          let falg = false; // 判断主副险
          for (let item in this.from.prodlist) {
            if (this.from.prodlist[item].falg) {
              falg = true;
              break;
            }
          }
          if (falg) {
            this.loading = true;
            this.inactive = true;
            let familyno = {
              familyno: this.$route.query.familyno,
              memid: this.$route.query.memid,
            };
            this.from.familyno = familyno;
            this.from.cpcode = this.from.prodlist[0].cpcode;
            this.from.comid = this.$route.query.comid;
            this.from.empno = this.$route.query.empno;
            generatereport({ ...this.from })
              .then((res) => {
                this.loading = false;
                this.inactive = false;
                if (this.$route.query.mold == "jiating") {
                  this.$router.push({
                    path: "/report",
                    query: {
                      comid: this.$route.query.comid,
                      familyno: this.$route.query.familyno,
                      personkey: this.$route.query.personkey,
                      empuserid: this.$route.query.empuserid,
                      familyname: this.$route.query.familyname,
                      fromwhere: this.$route.query.fromwhere,
                      type: this.$route.query.type,
                      empno: this.$route.query.empno,
                    },
                  });
                } else if (this.$route.query.mold == "geren") {
                  this.$router.push({
                    path: "/detail",
                    query: {
                      comid: this.$route.query.comid,
                      personkey: this.$route.query.personkey,
                      empuserid: this.$route.query.empuserid,
                      familyname: this.$route.query.familyname,
                      fromwhere: this.$route.query.fromwhere,
                      type: this.$route.query.type,
                      familyno: this.$route.query.familyno,
                      memid: this.$route.query.memid,
                      cardtype: this.$route.query.cardtype,
                      cardno: this.$route.query.cardno,
                      name: this.$route.query.name,
                      empno: this.$route.query.empno,
                    },
                  });
                } else if (this.$route.query.mold == "nbs") {
                  this.$router.push({
                    path: "/nbsReport",
                    query: {
                      familyname: this.$route.query.familyname,
                      familyno: this.$route.query.familyno,
                      comid: this.$route.query.comid,
                      empno: this.$route.query.empno,
                      fromwhere: this.$route.query.fromwhere,
                      personkey: this.$route.query.personkey,
                      custom: this.$route.query.custom,
                    },
                  });
                }
              })
              .finally(() => {
                this.loading = false;
                this.inactive = false;
              });
          } else {
            Toast.failed("最少选择一个主险");
          }
        } else {
          Toast.failed("请把信息填写完整!");
        }
      });
    },

    async getData(isInit = true) {
      let { pageNo, pageSize } = this;
      if (!isInit) {
        this.totalpage < pageNo || this.totalpage == pageNo
          ? (this.pageNo = this.totalpage)
          : (this.pageNo = ++pageNo);
        this.$refs.scrollView.finishLoadMore();
      }
      let res = await infolist({
        page: pageNo,
        size: pageSize,
        baseid: this.$route.query.baseid,
        ishas: this.ishas,
      });
      this.reportList = res.data.data.records;
      this.total = res.data.data.total;
      this.totalPage = res.data.data.pages;
    },
    showieffdata(item, index, i) {
      this.ieffdatatrue = i + 1;
      this.ieffdata = item;
      this.ieffdataindex = index;
      this.ieffdatai = i;
      this.$forceUpdate();
      this.isieffdata = true;
    },
    showSelector(item, index) {
      this.list = item;
      this.listindex = index;
      this.isSelectorShow = true;
    },
    selectzxjh(item, index) {
      this.list = item;
      this.list.classjh.forEach((i) => {
        i.value = i.jhcode;
        i.label = i.jhname;
      });
      this.listindex = index;
      this.isSeleczxJhShow = true;
    },
    selectjh(ic, index, i) {
      this.listic = ic;
      this.listic.classjh.forEach((i) => {
        i.value = i.jhcode;
        i.label = i.jhname;
      });
      this.listicindex = index;
      this.listici = i;
      this.isSelecJhShow = true;
    },
    showSelect(ic, index, i) {
      this.listic = ic;
      this.listicindex = index;
      this.listici = i;
      this.isSelecShow = true;
    },
    showiedata(ic, index, i, ieff) {
      this.ieffdatas = ic;
      this.ieffdataindexs = index;
      this.ieffdatais = i;
      this.ieffisieff = ieff;
      this.isieffdatas = true;
    },
    onSelectieff({ label }, index, i) {
      this.from.prodlist[index].effinfo[i].value = label;
    },
    onSelectorChoose({ label }, index) {
      this.from.prodlist[index].endnum = label;
    },
    onselectValue({ label }, index, i) {
      this.from.prodlist[index].fpordlist[i].endnum = label;
    },
    onSelectieffs({ label }, index, i, ieff) {
      this.from.prodlist[index].fpordlist[i].effinfo[ieff].value = label;
    },
    /* 主险计划下拉 */
    onselectzxjh({ label, value }, index) {
      this.from.prodlist[index].jhname = label;
      this.from.prodlist[index].jhcode = value;
    },
    /* 附加险计划下拉 */
    onselectfjh({ label, value }, index, i) {
      this.from.prodlist[index].fpordlist[i].jhname = label;
      this.from.prodlist[index].fpordlist[i].jhcode = value;
    },
  },
};
</script>
<style lang="stylus" scoped>
.md-example-popup {
  width: 100%;
  position: relative;
  font-size: 28px;
  font-family: DINPro;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  background-color: #FFF;
}

.md-example-popup-right {
  height: 100%;
  width: 80vw;
  display: flex;
  align-items: center;
}

.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
}

.messagelist {
  width: 90vw;
  padding-bottom: 10vh;
  margin: 0 auto;
  background-color: #F7F6FB;
}

.dangerlistce {
  height: 100vh;
  width: 95%;
  margin: 0 auto;
}

.dangerlistce p {
  height: 100px;
  line-height: 100px;
  border-bottom: 1px solid #ccc;
}

.dangerlistce .md-check {
  margin: 20px 0;
}

.dangerlist-bom {
  width: 80%;
  height: 7%;
  z-index: 5;
  position: fixed;
  bottom: 0;
}

.messagelist-bom {
  z-index: 5;
  width: 100vw;
  height: 7vh;
  position: fixed;
  bottom: 0;
  background: #fff;
}

.messfu {
  width: 80vw;
  margin: 0 auto;
  border: 1px solid #eee;
}

.message {
  background: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
}

.message-top {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
}

.message-top img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50px;
}

.message-top .md-agree {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0 20px 30px;
}

.message-top p {
  width: 100%;
  padding: 20px 0 20px 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-top .md-agree {
  float: right;
}

.messagelist-bomrg {
  width: 100%;
  height: 100%;
}

.md-example-popup-right .md-scroll-view {
  width: 100%;
  height: 100%;
}

.message-bompd {
  margin: 0 40px;
}

.md-button.block {
  height: 100%;
}

/deep/ .md-stepper-button:after {
  width: 35px;
  height: 3px;
}

/deep/ .md-stepper-button.md-stepper-button-add:before {
  width: 3px;
  height: 35px;
}

.md-radio-group .md-check-base-box {
  width: 120px;
}

/deep/ .md-toast .md-toast-text {
  background: #000;
}

/deep/ .md-selector .md-radio-item.is-selected .md-cell-item-title {
  color: #111a34;
}

.thesame {
  position: absolute;
  width: 1.7rem;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.3rem;
  right: 0.2rem;
  top: 0.15rem;
  border: 0.01rem solid;
}

.rightje /deep/ .md-field-item-control {
  text-align: right;
}

/deep/.md-field-item-title {
  font-size: 0.38rem;
}

/deep/.md-field-item-control {
  font-size: 0.4rem;
}

/deep/.md-input-item-input {
  font-size: 0.4rem;
}
</style>
